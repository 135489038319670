import React from 'react';
import { generatePath, Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

import { User } from './models/user';
import { getCurrentUser } from './api/session';
import { AppLoader } from './components/loader/app-loader';
import { PATH, SLUG } from './constants/path';
import { AppContext, AppContextValue } from './app-context';

import ltLocale from 'antd/lib/locale/lt_LT';
import enLocale from 'antd/lib/locale/en_US';
import HomePage from 'app/pages/home-page/home-page';

const PublicPage = React.lazy(() => import('./pages/public-page/public-page'));
const LoginPage = React.lazy(() => import('./pages/login-page/login-page'));
const PrivatePage = React.lazy(
  () => import('./pages/private-page/private-page')
);
const CustomerPage = React.lazy(
  () => import('./pages/customer-page/customer-page')
);
const DocumentPage = React.lazy(
  () => import('./pages/document-page/document-page')
);
const SettingsPage = React.lazy(
  () => import('./pages/settings-page/settings-page')
);
const SignPage = React.lazy(() => import('./pages/sign-page/sign-page'));
const SignListPage = React.lazy(
  () => import('./pages/sign-page/sign-list-page')
);
const SignConfirmationPage = React.lazy(
  () => import('./pages/sign-page/sign-confirmation-page')
);
const FillPage = React.lazy(() => import('./pages/sign-page/fill-page'));

export const App: React.FC = () => {
  const { i18n } = useTranslation();
  const [user, setUser] = React.useState<User | null>(null);
  const [zoom, setZoom] = React.useState(true);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const appContext = React.useMemo<AppContextValue>(
    () => ({
      user,
      zoom,
      setZoom,
    }),
    [user, zoom]
  );

  React.useEffect(() => {
    getCurrentUser()
      .then(setUser)
      .catch(() => undefined)
      .finally(() => setIsLoaded(true));
  }, []);

  if (!isLoaded) {
    return <AppLoader />;
  }

  return (
    <AppContext.Provider value={appContext}>
      <ConfigProvider
        locale={i18n.resolvedLanguage === 'lt' ? ltLocale : enLocale}
      >
        <Routes>
          <Route element={<PublicPage />}>
            <Route path={SLUG.LOGIN} element={<LoginPage />} />
          </Route>
          <Route
            element={
              user ? (
                <PrivatePage user={user} />
              ) : (
                <Navigate to={generatePath(PATH.LOGIN_PAGE)} replace={true} />
              )
            }
          >
            <Route index={true} element={<HomePage />} />
            <Route path={SLUG.CUSTOMER} element={<CustomerPage />} />
            <Route path={SLUG.DOCUMENT} element={<DocumentPage />} />
            <Route path={SLUG.SETTINGS} element={<SettingsPage />} />
          </Route>
          <Route path={SLUG.SIGN}>
            <Route index={true} element={<SignListPage />} />
            <Route path={SLUG.ID}>
              <Route index={true} element={<SignPage />} />
              <Route
                path={SLUG.CONFIRMATION}
                element={<SignConfirmationPage />}
              />
            </Route>
          </Route>
          <Route path={SLUG.FILL} element={<FillPage />} />
        </Routes>
      </ConfigProvider>
    </AppContext.Provider>
  );
};
