import i18nBase, { StringMap } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  initReactI18next,
  useTranslation as useTranslationBase,
} from 'react-i18next';

import lt from 'app/locales/lt.json';

export const defaultNS = 'translation';

export const resources = {
  lt: {
    [defaultNS]: lt,
  },
} as const;

i18nBase
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'lt',
    interpolation: {
      escapeValue: false,
    },
    resources,
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'htmlTag',
      ],
    },
  });

// i18n type override until i18next types are figured out
export const i18n = i18nBase as {
  t: (key: string, options?: StringMap) => string;
};

// useTranslation type override until i18next types are figured out
export const useTranslation = useTranslationBase as () => {
  t: (key: string, options?: StringMap) => string;
};
