import React from 'react';
import { Empty } from 'antd';

import { Page } from 'app/components/page/page';

const HomePage: React.FC = () => {
  return (
    <Page>
      <Empty description={false} />
    </Page>
  );
};

export default HomePage;
