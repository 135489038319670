import React from 'react';

import { User } from './models/user';

export interface AppContextValue {
  user: User | null;
  zoom: boolean;
  setZoom: (zoom: boolean) => void;
}

export const AppContext = React.createContext<AppContextValue>({
  user: null,
  zoom: true,
  setZoom: () => {},
});
