export const SLUG = {
  LOGIN: 'login',
  CUSTOMER: 'customer',
  DOCUMENT: 'document',
  SIGN: 'sign',
  FILL: 'fill',
  SETTINGS: 'settings',
  CONFIRMATION: 'confirmation',
  ID: ':id',
};

export const PATH = {
  ROOT_PAGE: '/',
  LOGIN_PAGE: `/${SLUG.LOGIN}`,
  CUSTOMER_PAGE: `/${SLUG.CUSTOMER}`,
  DOCUMENT_PAGE: `/${SLUG.DOCUMENT}`,
  SIGN_LIST_PAGE: `/${SLUG.SIGN}`,
  SIGN_PAGE: `/${SLUG.SIGN}/${SLUG.ID}`,
  SIGN_CONFIRMATION_PAGE: `/${SLUG.SIGN}/${SLUG.ID}/${SLUG.CONFIRMATION}`,
  FILL_PAGE: `/${SLUG.FILL}`,
  SETTINGS_PAGE: `/${SLUG.SETTINGS}`,
};
